import type { Sector } from '~/types/base'

const sectorList: Array<Sector> = [
  {
    title: 'config.sectors.toolmaking.title',
    name: 'toolmaking',
    headline: 'config.sectors.toolmaking.headline',
    text: 'config.sectors.toolmaking.text',
    route: '#',
    backgroundImage: {
      source: '/img/backgrounds/placeholder-sector-aerotec-background.webp',
    },
  },
  {
    title: 'config.sectors.medical-technology.title',
    name: 'medical-technology',
    headline: 'config.sectors.medical-technology.headline',
    text: 'config.sectors.medical-technology.text',
    route: '#',
    backgroundImage: {
      source:
        '/img/backgrounds/placeholder-sector-turbine-construction-background.webp',
    },
  },
  {
    title: 'config.sectors.power-engineering.title',
    name: 'power-engineering',
    headline: 'config.sectors.power-engineering.headline',
    text: 'config.sectors.power-engineering.text',
    route: '#',
    backgroundImage: {
      source: '/img/backgrounds/placeholder-sector-aerotec-background.webp',
    },
  },
  {
    title: 'config.sectors.food-technology.title',
    name: 'food-technology',
    headline: 'config.sectors.food-technology.headline',
    text: 'config.sectors.food-technology.text',
    route: '#',
    backgroundImage: {
      source:
        '/img/backgrounds/placeholder-sector-turbine-construction-background.webp',
    },
  },
  {
    title: 'config.sectors.aerotechnics.title',
    name: 'aerotechnics',
    headline: 'config.sectors.aerotechnics.headline',
    text: 'config.sectors.aerotechnics.text',
    route: '#',
    backgroundImage: {
      source: '/img/backgrounds/placeholder-sector-aerotec-background.webp',
    },
  },
  {
    title: 'config.sectors.engineering.title',
    name: 'engineering',
    headline: 'config.sectors.engineering.headline',
    text: 'config.sectors.engineering.text',
    route: '#',
    backgroundImage: {
      source:
        '/img/backgrounds/placeholder-sector-turbine-construction-background.webp',
    },
  },
  {
    title: 'config.sectors.turbine-construction.title',
    name: 'turbine-construction',
    headline: 'config.sectors.turbine-construction.headline',
    text: 'config.sectors.turbine-construction.text',
    route: '#',
    backgroundImage: {
      source: '/img/backgrounds/placeholder-sector-aerotec-background.webp',
    },
  },
]

export default sectorList
